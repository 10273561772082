import {
  Component,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { StudentService } from "../../../Schools/services/student.service";
import { alertService } from "../../Services/alert/alert.service";
import * as XLSX from "ts-xlsx";

@Component({
  selector: "guardianupload-modal",
  templateUrl: "./guardiansupload.component.html",
  styleUrls: ["./guardiansupload.scss"],
})
export class GuardiansUpload {
  @Output() IsGuardiansUploaded = new EventEmitter<boolean>();
  @ViewChild("uploader") uploader: any;
  xcelfile: any = null;
  xcelfileName: string = null;
  IsUploaded: Boolean = false;
  arrayBuffer: any;
  reserros = null;
  close: boolean = false;
  modalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private studentService: StudentService,
    private alert: alertService
  ) {}

  openModal(template: TemplateRef<any>) {
    this.clearPopupData();
    this.modalRef = this.modalService.show(template);
  }

  closeError() {
    this.close = false;
  }
  fileChangeEvent(event: any) {
    debugger;
    if (event.target.files[0].type.search("sheet") == -1) {
      return;
    }
    if (event.target.files && event.target.files[0].size > 0) {
      this.xcelfile = event.target.files[0];
      this.xcelfileName = event.target.files[0].name;
    }
  }
  readexcel() {
    /* convert data to binary string */
    if (this.xcelfile != null) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;

        var data = new Uint8Array(this.arrayBuffer);

        var arr = new Array();
        for (var i = 0; i != data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
          //  console.log("Data"+data[i]);
        }
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        //console.log("Data"+bstr);
        var first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        var worksheet = workbook.Sheets[first_sheet_name];
        var json = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 3, raw: true }
        );

        this.studentService.uploadGuardians(json).subscribe((res) => {
          debugger;
          this.reserros = res;
          this.close = true;
          this.IsUploaded = true;
        });
      };
      fileReader.readAsArrayBuffer(this.xcelfile);
    }
  }

  Save() {
    this.modalRef.hide();
    if (this.IsUploaded) this.IsGuardiansUploaded.emit(true);

    this.clearPopupData();
  }

  clearPopupData() {
    this.xcelfile = null;
    this.reserros = null;
    this.close = false;
    this.xcelfileName = null;
    this.IsUploaded = false;
  }
}

import { Component, TemplateRef, Input} from '@angular/core';
import { Students } from '../../../Schools/dataModel/students.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PlandetailsService } from '../../../Schools/services/plandetails.service';
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'student-list-popup',
  templateUrl: './student-list-popup.component.html',
  styleUrls: ['./student-list-popup.component.css']
})
export class StudentListPopupComponent {
  @Input() Count: number;
  @Input() StudentList: Students[] = [];
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService, private _planService: PlandetailsService, _tran: TranslateService) {  }

  ngOnInit() {
    
  }
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    console.log(this.Count);
    console.log(this.StudentList);
  }
}

import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";

//Services
import { AuthorizationService } from "./shared/Services/authorization/authorization.service";
import { AuthenticationService } from "./shared/Services/authentication/authentication.service";
import { TranslateService } from "@ngx-translate/core";

// data Model
import { PermissionDataModel } from "./shared/datamodels/permissions.data.model";
import { MenuLinkService } from "./shared/Services/menu/menu-link.service";
import { LoaderService } from "./shared/loader.service";
import { Subscription } from "rxjs";

import * as signalR from "@aspnet/signalr";
import { HubConnection } from "@aspnet/signalr";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [
    "./app.component.scss",
    "../assets/fms_branding/styles/sass/rtl.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  private _hubConnection: HubConnection | undefined;

  displayLoader: boolean = false;
  displayLoaderInSub: Subscription;
  menuState = false;
  submenuState: boolean;
  Permissions: PermissionDataModel[] = [];

  constructor(
    private AuthenticationService: AuthenticationService,
    private AuthorizationService: AuthorizationService,
    public menuservice: MenuLinkService,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService,
    public translateService: TranslateService
  ) {
    translateService.addLangs(["en", "ar"]);
    translateService.setDefaultLang("en");

    const browserLang = translateService.getBrowserLang();
    translateService.use(this.AuthenticationService.getCurrentculture());
  }
  subscriber() {
    this.displayLoaderInSub = this.loaderService.loaderCount$.subscribe(
      (displayLoader) => {
        if (displayLoader == 0) {
          if (this.displayLoader != false) {
            this.displayLoader = false;
            this.cd.detectChanges();
          }
        } else {
          if (this.displayLoader != true) {
            this.displayLoader = true;
            this.cd.detectChanges();
          }
        }
      }
    );
  }
  ngOnInit(): void {
    // this._hubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl('http://localhost:42068/notify')
    //   // .configureLogging(signalR.LogLevel.Information)
    //   .build();

    //  this._hubConnection.start().catch(err => console.error(err.toString()));

    // this._hubConnection.on('BroadcastMessage', (type: any , PayLoad:any) => {
    //   const received = `Received: ${type + ' ' + PayLoad}`;
    //   console.log(received);
    // });

    this.subscriber();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.AuthenticationService.validUser()) {
      let sso: string = this.AuthenticationService.getCurrentUser();
      let UserId = sso.split("*")[0];
      let SubId = sso.split("*")[1];
      this.AuthorizationService.getPermissions(UserId, SubId).subscribe(
        (res) => {
          this.Permissions = res;
        },
        (err) => {
          //console.log(err);
        }
      );
    } else {
      window.location.href = this.menuservice.getLink("login", "Altair");
    }
  }
  toggleMenu() {
    this.menuState = !this.menuState;
    if (!this.menuState) {
      this.submenuState = false;
    }
  }
  hideMenu() {
    this.menuState = false;
    this.submenuState = false;
  }
  toggleSubmenu($event) {
    this.submenuState = $event;
  }

  ngOnDestroy() {
    this.displayLoaderInSub.unsubscribe();
  }
}
